<template>
  <div>
    <ExamplesBar/>
    <v-container fluid class="mx-auto">
      <carousel-3d :controls-visible="false" :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
        <div id="link">
          <v-btn
            elevation="2"
            block
            small
            dark
            color="rgba(255, 198, 7, 0.8)"
            class="mx-auto"
            href="https://zipgorelok.ru/"
            target="_blank"
            >
          <p>view 4D Webgl</p>
            <v-icon class="ml-auto">
              mdi-arrow-right-drop-circle-outline
            </v-icon>
          </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/QvpxAXiq9yg" />
            </div>
          </div>
        </slide>
        <slide :index="1" id="back_slide">
          <div id="link">
            <v-btn
                elevation="2"
                block
                small
                dark
                color="rgba(255, 198, 7, 0.8)"
                class="mx-auto"
                href="/examples/verge3d/scroll_animation/scroll_animation.html"
                target="_blank"
                >
                <p>view 4D Webgl</p>
                <v-icon class="ml-auto">
                  mdi-arrow-right-drop-circle-outline
                </v-icon>
              </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/QxwltQovaJ4" />
            </div>
          </div>
        </slide>
        <slide :index="2" id="back_slide">
          <div id="link">
                <v-btn
                elevation="2"
                block
                small
                dark
                color="rgba(255, 198, 7, 0.8)"
                class="mx-auto"
                href="/examples/verge3d/ring/ring.html"
                target="_blank"
                >
                <p>view 4D Webgl</p>
                <v-icon class="ml-auto">
                  mdi-arrow-right-drop-circle-outline
                </v-icon>
              </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/CcIOxOq6-Ww" />
            </div>
          </div>
        </slide>
        <slide :index="3" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/video360/video360.html"
              target="_blank"
              >
              <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/taeTg3oB4FI" />
            </div>
          </div>
        </slide>
        <slide :index="4" id="back_slide">
          <div id="link">
                <v-btn
                elevation="2"
                block
                small
                dark
                color="rgba(255, 198, 7, 0.8)"
                class="mx-auto"
                href="/examples/verge3d/animaciya-kubika-rubika/animaciya-kubika-rubika.html"
                target="_blank"
                >
                <p>view 4D Webgl</p>
                <v-icon class="ml-auto">
                  mdi-arrow-right-drop-circle-outline
                </v-icon>
              </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/SN5Lbwjso5Q" />
            </div>
          </div>
        </slide>
      </carousel-3d>
      <carousel-3d :controls-visible="false" :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/parametric_models/index.html"
              target="_blank"
              >
             <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/5eg-TrJXLJ4" />
            </div>
        </slide>
        <slide :index="1" id="back_slide">
        <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/recliner/index.html"
              target="_blank"
              >
            <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/f8ELv6Z-kOo" />
            </div>
          </div>
        </slide>
        <slide :index="2" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/swiss_army_knife/index.html"
              target="_blank"
              >
             <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/4LpbVEFESac" />
            </div>
          </div>
        </slide>
        <slide :index="3" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/scooter/index.html"
              target="_blank"
              >
             <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/x4F-7dNXP2c" />
            </div>
          </div>
        </slide>
        <slide :index="4" id="back_slide">
        <div id="link">
          <v-btn
            elevation="2"
            block
            small
            dark
            color="rgba(255, 198, 7, 0.8)"
            class="mx-auto"
            href="/examples/verge3d/teapot_heater/index.html"
            target="_blank"
            >
          <p>view 4D Webgl</p>
            <v-icon class="ml-auto">
              mdi-arrow-right-drop-circle-outline
            </v-icon>
          </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/mW9nCTC3Ua4" />
            </div>
          </div>
        </slide>
      </carousel-3d>
      <carousel-3d :controls-visible="false" :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/clipping_planes/clipping_planes.html"
              target="_blank"
              >
             <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/bDHGOZTkTnI" />
            </div>
        </slide> 
        <slide :index="1" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/pause_render/pause_render.html"
              target="_blank"
              >
             <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/gJH4PMSS57o" />
            </div>
        </slide>
        <slide :index="2" id="back_slide">
            <div id="link">
              <v-btn
                elevation="2"
                block
                small
                dark
                color="rgba(255, 198, 7, 0.8)"
                class="mx-auto"
                href="/examples/verge3d/explosion_mesh/explosion_mesh.html"
                target="_blank"
                >
               <p>view 4D Webgl</p>
                <v-icon class="ml-auto">
                  mdi-arrow-right-drop-circle-outline
                </v-icon>
              </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/m47LzEQt-fo" />
              </div>
            </div>
        </slide>
        <slide :index="3" id="back_slide">
            <div id="link">
              <v-btn
                elevation="2"
                block
                small
                dark
                color="rgba(255, 198, 7, 0.8)"
                class="mx-auto"
                href="/examples/verge3d/webgl_physics_curtains/webgl_physics_curtains.html"
                target="_blank"
                >
               <p>view 4D Webgl</p>
                <v-icon class="ml-auto">
                  mdi-arrow-right-drop-circle-outline
                </v-icon>
              </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/3pgLLveOzCs" />
              </div>
            </div>
        </slide>
        <slide :index="4" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/webgl_pulse/webgl_pulse.html"
              target="_blank"
              >
              <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/axe0MIrAWl8" />
            </div>
          </div>
        </slide>
      </carousel-3d>
      <carousel-3d :controls-visible="false" :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/jewelry_configurator/index.html"
              target="_blank"
              >
             <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/XTcd2Ocy548" />
            </div>
          </div>
        </slide>
        <slide :index="1" id="back_slide">
            <div id="link">
              <v-btn
                elevation="2"
                block
                small
                dark
                color="rgba(255, 198, 7, 0.8)"
                class="mx-auto"
                href="/examples/verge3d/pointlights/pointlights.html"
                target="_blank"
                >
               <p>view 4D Webgl</p>
                <v-icon class="ml-auto">
                  mdi-arrow-right-drop-circle-outline
                </v-icon>
              </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/gx9yKi_mNPM" />
              </div>
            </div>
        </slide>
        <slide :index="2" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/video_double/video_double.html"
              target="_blank"
              >
              <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/PUmd517Ev_k" />
            </div>
          </div>
        </slide>
        <slide :index="3" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/ripple_water_verge3d/ripple_water_verge3d.html"
              target="_blank"
              >
              <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/k_HbAsVnj8c" />
            </div>
          </div>
        </slide>
        <slide :index="4" id="back_slide">
          <div id="link">
                <v-btn
                elevation="2"
                block
                small
                dark
                color="rgba(255, 198, 7, 0.8)"
                class="mx-auto"
                href="/examples/verge3d/vizitka_3d/vizitka_conf.html"
                target="_blank"
                >
                <p>view 4D Webgl</p>
                <v-icon class="ml-auto">
                  mdi-arrow-right-drop-circle-outline
                </v-icon>
              </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/vATMP1EXr6I" />
            </div>
          </div>
        </slide>
      </carousel-3d>
      <carousel-3d :controls-visible="false" :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/webgl_physics_curtains/webgl_physics_curtains.html"
              target="_blank"
              >
             <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/jIMiHFlTD10" />
            </div>
          </div>
        </slide>
        <slide :index="1" id="back_slide">
            <div id="link">
              <v-btn
                elevation="2"
                block
                small
                dark
                color="rgba(255, 198, 7, 0.8)"
                class="mx-auto"
                href="/examples/verge3d/landing_page_tele2/landing_page_tele2.html"
                target="_blank"
                >
               <p>view 4D Webgl</p>
                <v-icon class="ml-auto">
                  mdi-arrow-right-drop-circle-outline
                </v-icon>
              </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/13Q2713zLXI" />
              </div>
            </div>
        </slide>
        <slide :index="2" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/scroll_gsap_verge3d/scroll_gsap_verge3d.html"
              target="_blank"
              >
              <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/WavZXombEGw" />
            </div>
          </div>
        </slide>
        <slide :index="3" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/damping/damping.html"
              target="_blank"
              >
              <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/-iG3MDFbpvw" />
            </div>
          </div>
        </slide>
        <slide :index="4" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/light_probe/light_probe.html"
              target="_blank"
              >
              <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/L_WHU2cZfr4" />
            </div>
          </div>
        </slide>
      </carousel-3d>
      <carousel-3d :controls-visible="false" :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/print_webgl_physics/print_webgl_physics.html"
              target="_blank"
              >
             <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/y_PnlKfEi8s" />
            </div>
          </div>
        </slide>
        <slide :index="1" id="back_slide">
            <div id="link">
              <v-btn
                elevation="2"
                block
                small
                dark
                color="rgba(255, 198, 7, 0.8)"
                class="mx-auto"
                href="/examples/verge3d/virtualJoystick-example/virtualJoystick.html"
                target="_blank"
                >
               <p>view 4D Webgl</p>
                <v-icon class="ml-auto">
                  mdi-arrow-right-drop-circle-outline
                </v-icon>
              </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/oi0Nz9AI2vI" />
              </div>
            </div>
        </slide>
        <slide :index="2" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/teleport-example-blender/teleport-example-blender.html"
              target="_blank"
              >
              <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/JPUnYeM9soM" />
            </div>
          </div>
        </slide>
        <slide :index="3" id="back_slide">
          <div id="link">
            <v-btn
              elevation="2"
              block
              small
              dark
              color="rgba(255, 198, 7, 0.8)"
              class="mx-auto"
              href="/examples/verge3d/lava/lava.html"
              target="_blank"
              >
              <p>view 4D Webgl</p>
              <v-icon class="ml-auto">
                mdi-arrow-right-drop-circle-outline
              </v-icon>
            </v-btn>
          </div>
          <div id="youtube">
            <div>
              <LazyYoutubeVideo src="https://www.youtube.com/embed/tVFegT28A6o" />
            </div>
          </div>
        </slide>
      </carousel-3d>
    </v-container>
  </div>
</template>
<script>
import ExamplesBar from '@/components/controls_vue/ExamplesBar.vue';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import LazyYoutubeVideo from "vue-lazy-youtube-video";
  export default {
    name: 'Verge3D',
    components: {
      Carousel3d,
      Slide,
      LazyYoutubeVideo,
      ExamplesBar,
    },
    data: () => ({
      length: 3,
      onboarding: 0,
    }),

    methods: {
      next () {
        this.onboarding = this.onboarding + 1 === this.length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
          ? this.length - 1
          : this.onboarding - 1
      },
    },
  }
</script>
<style scoped>
#carousel {
  background-color: transparent;
}
#link {
    top: 0;
    left: 0;
    overflow: inherit;
    background: transparent; /* Цвет фона */
}
#youtube {
    top: 10px;
    position: relative;
    padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    height: 0;
    overflow: hidden;
    background-color: transparent;

}
p {
  position: absolute;
  color: #265787;
  margin: 20px;
  opacity: 1;
  transition: opacity 0.6s;
 font-size: 15pt;
 font-family: "ToxicPowers";
}
router-link li a {
    text-decoration: none;
}
#back_slide {
  background-color: transparent;
  padding: 10px;

}
@font-face {
font-family: "ToxicPowers";
src: url("/main/preloader/ToxicPowers.ttf");
/* font-style: normal; */
/* font-weight: normal; */
}
</style>
